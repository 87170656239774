.actionIcons{
    color: #fff!important;
    padding: 5px 10px;
    border-radius: 5px;
}

.gridBlock{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 15px;
    background-color: #a0a0a0;
    padding: 15px;
    border-radius: 10px;
    box-shadow: inset 0px 0px 10px -5px gray ;
}

.citem{
    /* width: 100px; */
    height: 175px;
    background-color: #f5f5f5;
}