* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #0039ff;
  border-radius: 100px;
}

fieldset{
  padding: 15px;
  margin-bottom:15px;
  border-radius: 10px;
  border: 1px solid silver;

}

legend{
  border: 1px solid silver!important;
  padding: 2px 15px!important;
  border-radius: 25px;
  width: auto!important;
  margin-bottom: 0px!important;
  color: black;
  font-size: 14px;
}
.ant-input-number{
  width: 100%;
}
.ant-form-item{
  margin-bottom: 10px;
}
.ant-btn{
  display: flex;
  align-items: center;
  justify-content: center;
}
